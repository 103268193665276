<template>
  <dash-page-new
      :title="$t('MySchedule')"
      :subtitle="$t('Schedule')"
      :root="$t('Dashboard')"
  >

    <v-sheet :color="wsLIGHTACCENT" class="py-2 px-5 d-flex justify-space-between my-6  wsRoundedLight">
      <schedule-week-selector
          v-model="selectedWeek"
          :pairs="pairs"
          :selected-week="selectedWeek"
      />
    </v-sheet>

    <div v-for="(pairs,day) in pairsItems" :key="day">
      <h3 v-if="pairs.length > 0 && pairs.map(el => el.is_blank).filter(el => !!el).length <  pairs.length " class="mb-2 mt-5">{{ $t(`Day_${day}`) }} </h3>


      <schedule-pair-teacher-viewer
          v-for="(pair , i) in pairs" :key="i + day + 'pair' "
          :pair="pair"
          :disciplines-select="disciplinesSelect"
          :selected-week="selectedWeek"
          :pair-types="pairTypes"
          :teachers-select="teachersSelect"
          :degree-alias="degreeAlias"
          :subgroupsSelect="subgroupsSelect"
          :i="i"
          @new-pair="handleNewPair"
          @update="updatePair"
          @delete="deletePair"
          @blank="handleBlankPair"
          @new-discipline="handleNewTeacherDiscipline"
      />

    </div>

  </dash-page-new>
</template>

<script>
import schedulePairTeacherViewer
  from "@/components/university/pages/schedule/scheduleViewer/pair/teacher/schedulePairTeacherViewer";
import scheduleWeekSelector from "@/components/university/pages/schedule/scheduleEditor/scheduleWeekSelector";

import {mapActions} from "vuex";

export default {
  name: "scheduleTeacherViewer",
  components : {
    scheduleWeekSelector,
    schedulePairTeacherViewer,
  },
  props : {
    uuid : {
      type : String,
    }
  },
  data() {
    return {
      entity : {},
      pairs : [],
      selectedWeek : 1,
      currentWeekPairs : [],
      disciplinesSelect : [],
      pairTypes : [],
      teachersSelect : [],
      degreeAlias : null,
      subgroupsSelect : [],
      dropdown : false,

      pairsItems : []
    }
  },
  computed : {
    pairsSelect() {
      return [
        { text : 1 + ' ' +  this.$t('Pair'), value : 1 },
        { text : 2 + ' ' +  this.$t('Pair'), value : 2 },
        { text : 3 + ' ' +  this.$t('Pair'), value : 3 },
        { text : 4 + ' ' +  this.$t('Pair'), value : 4 },
        { text : 5 + ' ' +  this.$t('Pair'), value : 5 },
        { text : 6 + ' ' +  this.$t('Pair'), value : 6 },
        { text : 7 + ' ' +  this.$t('Pair'), value : 7 },
      ]
    }
  },
  watch : {
    selectedWeek() {
      this.preparePairsItems()
    }
  },
  methods : {
    ...mapActions('wsu_schedule' , [
        'GET_TEACHER_SCHEDULE',
    ]),

    handleBlankPair(pair) {
      this.addPair(pair.number , pair.day , true)
    },
    deletePair(pair) {
      let index = this.pairs.findIndex(el => el.uuid === pair.uuid)
      if ( index === -1 ) {
        return
      }
      this.pairs.splice(index , 1)
    },
    updatePair($event) {
      let index = this.pairs.findIndex(el => el.uuid === $event.uuid )
      this.pairs[index] = this.COPY($event)
      this.pairs = this.COPY(this.pairs)
      this.preparePairsItems()
    },
    handleNewPair($event) {
      this.pairs.push($event)
    },
    handleNewTeacherDiscipline($event) {
      let index = this.teachersSelect.findIndex(el => el.value === $event.teacher)
      if ( index === -1 ) {
        return
      }

      if ( !this.teachersSelect[index].disciplines[this.degreeAlias] ) {
        this.teachersSelect[index].disciplines[this.degreeAlias] = [$event.discipline]
      } else {
        this.teachersSelect[index].disciplines[this.degreeAlias].push($event.discipline)
      }

    },
    getDayPairs(day) {
      let items = []
      let dayObject = {}
      let pairs = this.COPY(this.pairs)

      pairs = pairs.filter(el => el.day === day && el.week <= this.selectedWeek )
      pairs.forEach(el => {
        // PREPARATION
        el.groups = [el.academic_group_name]
        el.data = el.data.filter(el =>
                el.teacher_user_id === this.$store.state.auth.user.id
                && ( !el.is_alternating_pair || (
                        ( !el.is_alternate_second && this.selectedWeek%2 !== 0 && !el.is_free_time ) ||
                        (  el.is_alternate_second && this.selectedWeek%2 === 0 && !el.is_free_time )
                    )
                )
        )

        if ( el.data.length === 0 ) {
          el.is_blank = true
          el.groups = []
          el.discipline_id = null
        } else {
          el.discipline_id = el.data[0].discipline_id
        }
        // MAIN ACTION

        if ( !dayObject[el.number] ) {
          dayObject[el.number] = el
        } else {
          if (el.week >= dayObject[el.number].week) {
            let groups = dayObject[el.number].groups
            if ( el.discipline_id && el.discipline_id !== dayObject[el.number].discipline_id && el.uuid !== dayObject[el.number].uuid ) {

              dayObject[el.number] = el

            } else if ( el.discipline_id === dayObject[el.number].discipline_id && el.uuid !== dayObject[el.number].uuid && el.type === dayObject[el.number].type )  {
              console.log(el.groups , dayObject[el.number].groups)
              if ( el.groups.length > 0 && !groups.includes(el.groups[0])  ) {
                groups.push(el.groups[0])
              }
              dayObject[el.number].groups = groups
              let pairData = dayObject[el.number].data
              let pairArray = dayObject[el.number].data.map(el => el.discipline_id)
              el.data.forEach(item => {
                if ( !pairArray.includes(item.discipline_id) ) {
                  pairData.push(item)
                }
              })
              dayObject[el.number].data = pairData
              if ( dayObject[el.number].data.length > 0 ) {
                dayObject[el.number].is_blank = false
              }
            }

          }

        }
      })
      Object.keys(dayObject).forEach(number => {
        items.push(dayObject[number])
      })

      return items

    },
    getAvailablePairs(day) {
      let items = this.pairsSelect

      let pairsNumbersArray  = this.getDayPairs(day).filter(el => !el.is_blank).map(el => el.number)

      if ( pairsNumbersArray.length > 0 ) {
        items = items.filter(el => !pairsNumbersArray.includes(el.value))
      }

      return items
    },

    preparePairsItems() {
      for ( let i = 1 ; i <=7; i++ ) {
        this.pairsItems.push([])
        this.pairsItems[i] = this.COPY(this.getDayPairs(i))
      }
    },

    async initPage() {
      this.isTeacher = this.$route.path.includes('teacher_schedule')
      let result = this.isTeacher ?  await this.GET_TEACHER_SCHEDULE() : false
      if ( !result ) {
        return
      }
      this.entity = result.schedule
      this.pairs = result.pairs
      this.disciplinesSelect = result.disciplines
      this.pairTypes = result.pair_types
      this.teachersSelect = result.teachers
      this.degreeAlias = result.degree_alias
      this.subgroupsSelect = result.subgroups

      this.preparePairsItems()
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>