<template>
  <v-sheet v-if="!pair.is_blank"  class="wsRoundedHalf mb-2" :style="`border : 1px solid ${wsBACKGROUND}`">
    <!-- Header-->
    <v-sheet dark class="pa-4 wsRoundedHalfTop d-flex justify-space-between align-center" :color="wsDARKLIGHT">
      <h5>{{  pair.number  }} {{ $t('Pair') }} / {{  pair.time_start  }} - {{  pair.time_end  }}  </h5>

    </v-sheet>

    <div v-for="(pairsData , j) in pairsDataArray" :key="j">
      <schedule-pair-data-teacher-viewer
          v-for="(data , i ) in pairsData.items" :key="i + 'pair' + j"
          :item="data"
          :disciplines-select="disciplinesSelect"
          :selected-week="selectedWeek"
          :pair-types="pairTypes"
          :teachers-select="teachersSelect"
          :degree-alias="degreeAlias"
          :subgroups-select="getSubgroupsSelect(pairsData.items)"
          :display-delete="pairsData.items.length > 1"
          :groups="pair.groups"
          :is-alternating-pair="pair.is_alternating"
          @delete="deletePairData"
          @new-pair="$emit('new-pair' , $event)"
          @new-discipline="$emit('new-discipline', $event )"
          @update="updatePairData"
      />
    </div>



  </v-sheet>
</template>

<script>
import schedulePairDataTeacherViewer
  from "@/components/university/pages/schedule/scheduleViewer/pair/teacher/schedulePairDataTeacherViewer";
import {mapActions} from "vuex";
export default {
  name: "schedulePairEditor",
  components : {
    schedulePairDataTeacherViewer
  },
  props : {
    pair : {
      type : Object,
      default() { return {} }
    },
    selectedWeek : {
      type : Number,
      default : 1
    },
    disciplinesSelect : {
      type : Array,
      default() { return [] }
    },
    pairTypes : {
      type : Array,
      default() { return [] }
    },
    teachersSelect : {
      type : Array,
      default() { return [] }
    },
    degreeAlias : {
      type : String,
      default : 'msc'
    },
    subgroupsSelect : {
      type : Array,
      default() { return [] }
    }
  },
  computed : {
    pairsDataArray() {
      let items = [
        {
          is_alternate: false,
          items: this.regularPairs
        }

      ]
      if ( this.pair.is_alternating ) {
        items.push(
            {
              is_alternate: true,
              items: this.alternatePairs
            }
        )
      }
      if ( this.selectedWeek%2 === 0) {
        items.reverse()
      }
      return items
    },
    regularPairs() {
      return this.pair.data.filter( el => !el.is_alternate_second)
    },
    alternatePairs() {
      return this.pair.data.filter( el => el.is_alternate_second)
    }
  },
  methods : {
    ...mapActions('wsu_schedule' , [
      'DELETE_SCHEDULE_PAIR',
      'EDIT_SCHEDULE_PAIR',
      'ADD_SCHEDULE_PAIR_SUBGROUP'
    ]),

    async updatePair(data) {

      if ( this.selectedWeek !== this.pair.week ) {
        data.new_pair = true
        data.week = this.selectedWeek
      }

      let result = await this.EDIT_SCHEDULE_PAIR(data)
      if ( !result ) {
        return
      }
      if ( data.new_pair ) {
        this.$emit('new-pair' , result )
      } else {
        this.$emit('update', result)
      }
    },

    deletePairData(item) {

      let data = {
        uuid : this.pair.uuid,
        delete_pair_data_id : item.uuid
      }

      this.updatePair(data)

    },
    handleAlternatingPair() {
      let data = {
        uuid : this.pair.uuid,
        is_alternating : !this.pair.is_alternating
      }

      this.updatePair(data)

    },
    addSubgroup(isAlternating = false) {
      let data = {
        uuid : this.pair.uuid,
        add_subgroup : true,
        is_alternate_second : isAlternating
      }
      this.updatePair(data)
    },
    async deletePair() {
      if ( this.pair.week !== this.selectedWeek ) {
        return this.$emit('blank' , this.pair)
      }
      let result = await this.DELETE_SCHEDULE_PAIR(this.pair.uuid)
      if ( !result ) {
        return this.$t('NetworkError')
      }
      this.$emit('delete' , this.pair)
    },

    // technical
    getSubgroupsSelect(pairsData) {
      let items = this.subgroupsSelect
      items = items.filter( el => !pairsData.map(item => item.subgroup_id).includes(el.value) )
      if ( pairsData.length === 1 && this.subgroupsSelect.length > 1) {
        items.push({ text : this.$t('AllGroup') , value : null })
      }
      return items
    },
    getDisplaySubgroupButtonCondition(items) {
      if ( items.length >= this.subgroupsSelect.length ) {
        return false
      }

      if ( items.length > 0 && items[0].subgroup_id ) {
        return true
      }

      return false
    },
    updatePairData(pairData) {
      let index = this.pair.data.findIndex(el => el.uuid === pairData.uuid )
      this.pair.data[index] = this.COPY(pairData)
      this.$emit('update' , this.COPY(this.pair) )
    },
  }
}
</script>

<style scoped>

</style>