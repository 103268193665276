<template>
<v-sheet
    :style="`border : 1px solid ${wsDARKLIGHT}`"
    class="px-1 py-1 d-flex align-center wsRoundedLight justify-space-between"
    width="242"
>
  <!-- Previous Week Button-->
  <v-btn :disabled="week < 2" @click="previousWeek" :color="wsACCENT" small :style="`background-color : ${wsLIGHTCARD}`" icon>
    <v-icon >mdi-arrow-left</v-icon>
  </v-btn>

  <!-- Week Selector Dropdown -->
  <v-menu
      offset-y
      nudge-bottom="7px"
      nudge-left="32px"
  >
    <template #activator="{ on, attrs }">
      <v-sheet
          v-on="on"
          v-bind="attrs"
          class="d-flex align-center justify-center"
          width="100%"
      >
        <h5 :style="`color : ${wsDARKER}`" class="text-center" style="margin-top: 2px" >
          {{ $t('Week') }} {{  week  }}
          <v-icon :color="wsDARKER">mdi-menu-down</v-icon>
        </h5>
      </v-sheet>
    </template>

    <v-sheet class="d-flex py-4 align-center justify-center" width="242">

      <v-sheet color="transparent" class="d-flex  flex-wrap" width="212">
        <v-sheet
            @click="week = number"
            v-for="number in maxWeek" :key="number"
            :style="`border : 1px solid ${wsBACKGROUND} !important`"
            :color="weekColor(number)"
            :dark="weekIsSelected(number)"
            class="d-flex mb-1 justify-center align-center pointer"
            :class="[{'mr-1' : number%6 !== 0 }]"
            height="32" width="32"
            v-ripple
        >
          <h5 :style="`color : ${weekIsSelected(number) ? '#ffffff' : wsACCENT}`">{{ number }}</h5>
        </v-sheet>
      </v-sheet>


    </v-sheet>


  </v-menu>

  <!-- Next Week Button-->
  <v-btn :disabled="week >= maxWeek" @click="nextWeek" :color="wsACCENT" small icon>
    <v-icon >mdi-arrow-right</v-icon>
  </v-btn>

</v-sheet>
</template>

<script>
export default {
  name: "scheduleWeekSelector",
  props : {
    value : {
      type : Number,
    },
    maxWeek : {
      type : Number,
      default : 16
    },
    pairs : {
      type : Array,
      default() { return [] }
    },
    selectedWeek : {
      type : Number
    }
  },
  data() {
    return {
      week : 1,
    }
  },
  computed : {
    weeksWithPairs() {
      const uniqueWeeks = new Set(this.pairs.map(item => item.week));
      return Array.from(uniqueWeeks);
    }
  },
  watch : {
    value() {
      if ( this.week !== this.value ) {
        this.week = this.value
      }
    },
    week() {
      if ( this.week !== this.value ) {
        this.$emit('input' , this.week)
      }
    }
  },
  methods : {
    previousWeek() {
      this.week--
    },
    nextWeek() {
      this.week++
    },
    // technical
    weekIsSelected(week) {
      return this.selectedWeek === week
    },
    weekColor(week) {
      if ( this.weekIsSelected(week) ) {
        return this.wsACCENT
      } else {
        return this.weeksWithPairs.includes(week) ? this.wsLIGHTACCENT : 'white'
      }
    }
  },
  mounted() {
    if ( this.value ) {
      this.week = this.value
    }
  }
}
</script>

<style scoped>

</style>