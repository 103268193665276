<template>
    <v-sheet :color="backgroundColor" class="pa-4 d-flex align-center "
             :class="[{'justify-space-between' : !item.is_free_time} , {'justify-center' : item.is_free_time}]"
             style="position: relative">

      <h4 v-if="item.is_free_time" class="py-3 text-center" :style="`color : ${wsACCENT}`" >
        {{ $t('wsu.schedule.pair.no_pair')  }}
        <v-btn icon>
          <v-icon class="pointer" @click="updateData(false , 'is_free_time')" :color="wsACCENT">mdi-close-circle</v-icon>
        </v-btn>
        </h4>

      <div v-if="!item.is_free_time" style="width: 90%" class="pr-2" >
        <h5>{{ item.component_name }} </h5>
        <div class="mt-3 d-flex">

          <h5 v-if="groups.length < 2" :style="`color : ${wsACCENT}`">
            {{ $t('Group') }} : {{ item.academic_group_name }}
          </h5>

          <h5 v-else  :style="`color : ${wsACCENT}`">
            {{ $t('Groups') }} :
            <span v-for="(group , i) in groups" :key="i"> {{ group }} </span>
          </h5>

        </div>

      </div>

      <div v-if="!item.is_free_time" class="d-flex align-center justify-end pl-2" style="width: 50%" >
        <!-- Pair Type Dropdown-->

        <h5 :style="`color : ${!item.pair_type_name ? wsDARKLIGHT : wsACCENT}`" class="font-weight-regular mr-3">
          {{ item.pair_type_name || $t('PairType')  }}
        </h5>

        <h5 :style="`color : ${!item.subgroup_name ? wsDARKLIGHT : wsACCENT}`" class="font-weight-regular mr-3">
          {{ item.subgroup_name || $t('AllGroup')  }}
        </h5>

        <v-hover #default="{hover}">
          <div class="d-flex align-center">
            <h5 @click="openEditLink"  :style="`color : ${item.link ? wsACCENT : wsDARKLIGHT}`" class="font-weight-regular pointer mr-3">
              {{ $t( item.link ? 'Link' : 'NoLink') }}
            </h5>
            <v-sheet color="transparent" width="20">
              <v-icon small :color="wsACCENT" v-if="hover">mdi-pencil</v-icon>
            </v-sheet>
          </div>
        </v-hover>

      </div>

      <ws-dialog
          v-if="displayLinkDialog"
          v-model="displayLinkDialog"
          :title="$t('wsu.schedule.pair.link.title')"
          @save="updateData(link , 'link')"
      >
        <ws-text-field
            v-model="link"
            :label="$t('Link')"
            :placeholder="$t('EnterLink')"
            clearable
        />
      </ws-dialog>

    </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "schedulePairDataTeacherViewer",
  components : {
  },
  props : {
    item : {
      type : Object ,
      default(){ return {} }
    },
    disciplinesSelect : {
      type : Array,
      default() { return [] }
    },
    selectedWeek : {
      type : Number,
      default : 1
    },
    isAlternatingPair : {
      type : Boolean,
      default : false
    },
    pairTypes : {
      type : Array,
      default() { return [] }
    },
    teachersSelect : {
      type : Array,
      default() { return [] }
    },
    degreeAlias : {
      type : String,
      default : 'msc'
    },
    groups : {
      type : Array,
      default() { return [] }
    },
    subgroupsSelect : {
      type : Array,
      default() { return [] }
    },
    displayDelete : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      componentSearch : '',
      selectedTeacher : {},
      selectedDepartment : '',
      displayDepartmentDialog : false,
      displayLinkDialog : false,
      link : ''
    }
  },
  computed : {
    disciplinesSelectFiltered() {
      let items = this.COPY(this.disciplinesSelect)
      if ( this.isAlternatingPair && !this.displayDelete ) {
        items.unshift({ text : `(${this.$t('wsu.schedule.pair.no_pair')})` , value : 'no_pair' })
      }
      return items
    },
    backgroundColor() {
      if ( this.isAlternatingPair ) {
        if ( !this.item.is_alternate_second && this.selectedWeek % 2 === 0 ) {
          return this.wsLIGHTCARD
        }
        else if ( this.item.is_alternate_second && this.selectedWeek % 2 !== 0 ) {
          return this.wsLIGHTCARD
        }
        return 'transparent'
      }
      return 'transparent'
    },
    teacherInputStyle() {

      let style = ''

      style += `color : ${this.wsACCENT};`
      style += `font-size : 13px;`
      style += `font-weight : 600;`

      return style
    },
    teachersSelectFiltered() {
      let items = this.COPY(this.teachersSelect)
      if ( this.item.discipline_id && this.degreeAlias) {
        items.sort((a) => {
         if ( a.disciplines[this.degreeAlias] &&  a.disciplines[this.degreeAlias].includes(this.item.discipline_id) ) {
           return -1
         } else {
           return 1
         }

        })
      }
      return items
    }
  },
  methods : {
    ...mapActions('wsu_schedule' , [
      'EDIT_SCHEDULE_PAIR_DATA'
    ]),
    openEditLink() {
      this.link = this.item.link || ''
      this.displayLinkDialog = true
    },

    async updateData(value , key) {
      if ( value === 'no_pair') {
        return this.updateData(true , 'is_free_time')
      }
      let data = this.COPY(this.item)
      data[key] = value
      if ( this.selectedWeek !== this.item.week ) {
        data.new_pair = true
        data.week = this.selectedWeek
      }
      let teacher = null
      if ( key === 'component_id' ) {
        data.teacher_id = null
      }
      if ( key === 'teacher_id') {
        teacher = value
      }
      if ( key === 'is_free_time' && value ) {
        data.component_id = null
      }
      let result = await this.EDIT_SCHEDULE_PAIR_DATA(data)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error')
      }

      if ( result.new_pair ) {
        this.$emit('new-pair' , result.new_pair )
      } else {
        this.$emit('update', result.pair_data)
      }

      if ( result.new_discipline ) {
        this.$emit('new-discipline' , { teacher : teacher , discipline : this.item.discipline_id })
      }

      this.displayDepartmentDialog = false
      this.displayLinkDialog = false

    }
  }

}
</script>

<style scoped>

</style>